.Join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}
.left-j{
    color:white ;
    font-weight:bold ;
    font-size: 3rem;
    text-transform: uppercase;
    position: relative;
}
.left-j>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange) ;
    border-radius: 20px;
    margin: -10px 0;
}
.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-container{
display: flex;
gap: 3rem;
background-color: gray;
padding: 1rem 2rem;
width: 20.5rem;
height: 4rem;
}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color:var(--lightgray);
    height: 4rem;
    width: 10rem;
}
::placeholder{
    color: var(--lightgray);
}
.btn-j{
    background-color: var(--orange);
    color: white;
    height: 4rem;
    width: 10rem;
}
@media screen and (max-width:768px) {
    .Join{
        flex-direction: column;
        gap: 1rem;
    }
    .left-j{
        font-size: x-large;
        flex-direction: column;
    }
    .right-j{
    padding: 1rem;
    }
   .email-container{
 position: relative;
 justify-content: center;
 align-items: center;
 right: 2.5rem;
 width: 16rem;
 height: 2.7rem;
 padding-right: 1rem;
 padding-left: 3rem;
   }

    }

